export const useDrupalApi = async (url: string, query: Object) => {
  const config = useRuntimeConfig()
  const nuxtApp = useNuxtApp()
  let baseURL = config.public.DRUPAL_BASE_URL
  if (process.client) {
    baseURL = ''
  }

  return await useFetch(url, {
    baseURL,
    query,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    getCachedData: (key) => {
      return nuxtApp.payload.data[key]
    },
  })
}
